import React from "react";
import "./index.scss";

import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import animateModule from "../../../modules/animateModule";
import { convertBytes } from "../../../modules/miscModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";
import { countries } from "../../../modules/countryModules";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import FilterPanel from "../../../components/customComponents/FilterPanelNew";
import Spinner from "../../../components/customComponents/Spinner";

const colors = {
    red: "#ff8d8d",
    yellow: "#fbff8b",
    green: "#6aff81"
};

const AdminDevices = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [filters, setFilters] = React.useState();
    const timestampRef = React.useRef();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const getStatus = (active, verified) => {
        if (!active && !verified) return <span style={{color: colors["yellow"]}}>Added, waiting verification</span>;
        if (verified && !active) return <span style={{color: colors["yellow"]}}>Verified, waiting integration</span>;
        return <span style={{color: colors["green"]}}>Verified and active</span>;
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/proxySlaves/getAllSlaves`,
            data: {
                limit: 20,
                offset: 0,
                filters,

                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (!data.data) return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/proxySlaves/getAllSlaves`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters,

                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [filters]);

    return <div className="route__admin__devices">

        <FilterPanel
            theme="dark"
            accent="rgb(62, 87, 166)"
            filters={[
                {name: "Name", friendlyName: "Name", type: "string"},
                {name: "GatewayIP", friendlyName: "IP", type: "string"},
                {name: "GatewayPort", friendlyName: "Port", type: "string"},
                {name: "GatewayProxyPort", friendlyName: "Proxy Port", type: "string"},
                {name: "GatewayVersion", friendlyName: "Version", type: "number"},
                {name: "isActive", friendlyName: "Is active", type: "boolean"},
                {name: "isInitialVerified", friendlyName: "Is initial verified", type: "boolean"},

                {name: "lastChecked", friendlyName: "Last checked date", type: "date"},
                {name: "lastHardwareUpdate", friendlyName: "Last hardware update date", type: "date"},
                {name: "createdAt", friendlyName: "Created date", type: "date"}
            ]}
            filterCB={f => {
                setFilters(f);
            }}
        />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme="dark"
            headers={["Name", "Location", "Status", "Version", "Devices", "Used", "SMS Devices", ""]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]]
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data!"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.Name},
                        {keyID: item.ID, type: "text", text: `${item.IP}:${item.Port}`},
                        {keyID: item.ID, type: "text", text: getStatus(item.Active, item.Verified)},
                        {keyID: item.ID, type: "text", text: item.Version},
                        {keyID: item.ID, type: "text", text: item.Slaves.length},
                        {keyID: item.ID, type: "text", text: item.Slaves.filter(s => s?.AssignedToUser).length},
                        {keyID: item.ID, type: "text", text: item.SMSSlaves.length},
                        {keyID: item.ID, type: "button", text: "Details", onClick: () => animateNavigate(`/admin-devices/${item.ID}`)}
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const AdminSingleDevice = () => {
    const [data, setData] = React.useState();
    const [allUsers, setAllUsers] = React.useState();

    let curParams = useParams();

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/proxySlaves/getAllSlaves`,
            data: {
                limit: 1,
                offset: 0,

                filters: [
                    {name: "ID", op: "eq", value: curParams?.id}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    return setData({status: "ok", data: res.data.data[0]});
                };
            };

            setData(backendModule.genericError);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const findUser = uid => {
        if (!allUsers) return <Spinner color="white" style={{width: "16px", height: "16px"}} />
        if (allUsers.status === "error") return "?";

        let curUser = allUsers.data.find(usr => usr.ID === uid);
        return curUser?.Username ? <span style={{color: "rgb(102, 238, 102)"}}>{curUser?.Username}</span> : "?";
    };

    const getTypeColor = (type) => {
        switch (type) {
            case "info":
                return "#fff";
            case "warning": return colors["yellow"];
            case "error":
            case "critical": return colors["red"];
            default: return "#fff";
        };
    };

    React.useEffect(() => {
        if (!curParams?.id) return;
        getData();
        getAllUsers();
    }, [curParams]);

    return <div className="route__admin__singleDevice">
        {(data && allUsers) ? <>
            {(data.status === "ok" && allUsers.status === "ok") ? <>
                <div className="route__admin__singleDevice__serverInfo" style={{marginBottom: "30px"}}>
                    <p>ID: {data.data.ID}</p>
                    <p>Name: {data.data.Name}</p>
                    <p>IP: {data.data.IP}</p>
                    <p>Port: {data.data.Port}</p>
                    <p>Proxy port: {data.data.ProxyPort}</p>
                    <p>Version: {data.data.Version}</p>
                    <p>Active: {data.data.Active ? "Yes" : "No"}</p>
                    <p>Verified: {data.data.Verified ? "Yes" : "No"}</p>
                    <p>Devices: {data.data.Slaves.length}</p>
                    <p>Date added: {moment(data.data.CreatedAt).toDate().toLocaleString()} ({moment(data.data.CreatedAt).fromNow()})</p>
                </div>

                <h3 style={{marginBottom: "5px"}}>Proxy interfaces</h3>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme="dark"
                    style={{width: "100%", columnGap: "20px"}}
                    headers={["Name", "Type", "IP", "Country", "Response time (ms)", "Avg. Download / Upload (10s)", "Assigned to"]}
                    data={(()=>{
                        let proxySlaves = data.data.Slaves.map(s => {
                            let curCountry = countries.find(c => c?.code.toLowerCase() === s.Country.toLowerCase());
                            curCountry = curCountry ? curCountry.name : "?";
    
                            return [
                                {keyID: s.Name, type: "text", text: s.Name},
                                {keyID: s.Name, type: "text", text: s.ProxyType},
                                {keyID: s.Name, type: "text", text: s.IP},
                                {keyID: s.Name, type: "text", text: curCountry},
                                {keyID: s.Name, type: "text", text: `${s.ResponseTime ?? "-"} ms`},
                                {keyID: s.Name, type: "text", text: `${convertBytes(s.SpeedRates.Download)} / ${convertBytes(s.SpeedRates.Upload)}`},
                                {keyID: s.Name, type: "text", text: s.AssignedToUser ? findUser(s.AssignedToUser) : "None"}
                            ];
                        });
                        if (proxySlaves.length === 0) proxySlaves.push([{keyID: "noData-noData", type: "custom", data: "No proxy devices to show..."}]);

                        return proxySlaves;
                    })()}
                />

                <h3 style={{marginBottom: "5px", marginTop: "25px"}}>SMS interfaces</h3>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme="dark"
                    style={{width: "100%", columnGap: "20px"}}
                    headers={["Name", "Number", "IMEI", "Country", "Total messages", "Last message at"]}
                    data={(()=>{
                        let smsSlaves = data.data.SMSSlaves.map(s => {
                            let curCountry = countries.find(c => c?.code.toLowerCase() === s.Country.toLowerCase());
                            curCountry = curCountry ? curCountry.name : "?";
    
                            return [
                                {keyID: s.Name, type: "text", text: s.Name},
                                {keyID: s.Name, type: "text", text: s.PhoneNumber},
                                {keyID: s.Name, type: "text", text: s.IMEI},
                                {keyID: s.Name, type: "text", text: curCountry},
                                {keyID: s.Name, type: "text", text: s.TotalMessages},
                                {keyID: s.Name, type: "text", text: moment(s.lastReceivedDate).fromNow(false)}
                            ];
                        });
                        if (smsSlaves.length === 0) smsSlaves.push([{keyID: "noData-noData", type: "custom", data: "No SMS devices to show..."}]);

                        return smsSlaves;
                    })()}
                />

                <h3 style={{marginBottom: "5px", marginTop: "25px"}}>Logs</h3>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme="dark"
                    style={{width: "100%", columnGap: "20px"}}
                    headers={["Date", "Type", "Message"]}
                    data={data.data.History.filter((_, idx) => idx < 99).map(h => {
                        return [
                            {keyID: h.ID, type: "text", text: <span style={{color: getTypeColor(h.Type)}}>{moment(h.Date).toDate().toLocaleString()}</span>,},
                            {keyID: h.ID, type: "text", text: <span style={{color: getTypeColor(h.Type)}}>{h.Type}</span>,},
                            {keyID: h.ID, type: "text", text: <span style={{color: getTypeColor(h.Type)}}>{h.Message}</span>,}
                        ];
                    })}
                />
            </> : <p>Error while fetching data</p>}
        </> : <Spinner color="white" />}
    </div>
};

export default AdminDevices;
export { AdminSingleDevice };