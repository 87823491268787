import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as backendModule from "../../../modules/backendModule";
import animateModule from "../../../modules/animateModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModules";
import { convertBytes } from "../../../modules/miscModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import FilterPanel from "../../../components/customComponents/FilterPanelNew";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Checkbox from "../../../components/customComponents/Checkbox";
import StyledInput from "../../../components/styledComponents/Input";

import YesNoModal from "../../../components/modals/YesNoModal";

const ProxyAssignments = () => {
    const [users, setUsers] = React.useState();
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [selectedAssigns, setSelectedAssigns] = React.useState([]);

    const timestampRef = React.useRef();
    const checkboxFunctionsRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/getAllAssigns`,
            data: {
                limit: 20,
                offset: 0,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/getAllAssigns`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const removeAssignments = (e, IDs) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["This will permanently remove ", <span style={{color: "rgb(108, 93, 211)"}}>{IDs.length} assignments</span>,", and will DISCONNECT the users, if they are using the proxy. Are you really sure?"]}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage("");

                for (let ID of IDs) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/users/proxyAssigns/unassign`,
                        data: {
                            ID
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                args.disabledAll(false);
                args.spinner(false);
                args.close();
            }}
        />);
    };

    const addQuotaToAssignments = (e, ids) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (ids.length === 0) return;

        let foundItems = data.data.filter(d => ids.includes(d.ID));
        if (foundItems.length === 0) return;

        animateBox(e, <AddQuota items={foundItems} onChange={() => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        }} />);
    };

    const resetQuotaUsage = (e, IDs) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (IDs.length === 0) return;

        let foundItems = data.data.filter(d => IDs.includes(d.ID));
        if (foundItems.length === 0) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["This will reset the usage for ", <span style={{color: "rgb(108, 93, 211)"}}>{IDs.length} quotas</span>,", effectively zero-ing them out, are you sure?"]}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage("");

                for (let q of foundItems) {
                    if (!q?._Quota) return;
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/quotas/resetQuotaUsage`,
                        data: {
                            ID: q._Quota.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                args.disabledAll(false);
                args.spinner(false);
                args.close();
            }}
        />);
    };

    const removeQuota = (e, IDs) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (IDs.length === 0) return;

        let foundItems = data.data.filter(d => IDs.includes(d.ID));
        if (foundItems.length === 0) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["This will remove ", <span style={{color: "rgb(108, 93, 211)"}}>{IDs.length} quotas</span>,", which means that they will have unlimited amount of data, are you sure?"]}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage("");

                for (let q of foundItems) {
                    if (!q?._Quota) return;
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/quotas/removeQuota`,
                        data: {
                            ID: q._Quota.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                args.disabledAll(false);
                args.spinner(false);
                args.close();
            }}
        />);
    };

    const viewQuotaHistory = ID => {
        if (!data) return;
        if (data.status !== "ok") return;

        for (let item of data.data) {
            if (item.ID === ID) {
                if (item._Quota) {
                    return animateNavigate(`/quota-history/${item._Quota.ID}`);
                };
            };
        };
    };

    const viewProxyHistory = ID => {
        return animateNavigate(`/admin-proxy-history/${ID}`);
    };

    const getQutaData = quota => {
        if (!quota) return <span style={{color: "gray"}}>N/A</span>;

        let qb = window.BigInt(quota.QuotaBytes);
        let ub = window.BigInt(quota.UsedBytes);

        let color = "#6aff81";
        if (qb <= ub) {
            color = "#ff8d8d";
        } else if ((ub * 100n) / qb >= 80n) {
            color = "#fbff8b";
        };

        let finalPercent = (ub * 100n) / qb;
        let tmp1 = convertBytes(ub);
        let tmp2 = convertBytes(qb);

        return <div className="route__proxyAssignments__quotaLine">
            <span style={{color: color}}>{tmp1} / {tmp2}</span>
            <span className="route__proxyAssignments__quotaLine__line">
                <span style={{width: `${finalPercent}%`, backgroundColor: color}}></span>
            </span>
        </div>;
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [filters]);

    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__proxyAssignments">
        <div className="route__proxyAssignments__buttons">
            <div className="route__proxyAssignments__buttons__left">
                <div className={`route__proxyAssignments__buttons__left__selected ${selectedAssigns.length > 0 ? "route__proxyAssignments__buttons__left__selected--active" : ""}`}>
                    {`${selectedAssigns.length} assigns selected`}
                    <div
                        className="route__proxyAssignments__buttons__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>
            <div className="route__proxyAssignments__buttons__right">
                {selectedAssigns.length === 0 && <div className="route__proxyAssignments__buttons__btn" onClick={e => {
                    animateBox(e, <Assign onChange={() => {
                        let t = Date.now();
                        timestampRef.current = t;
                        getData(t);
                    }} />)
                }}>
                    <img src="/images/icon_close.svg" style={{transform: "rotate(45deg)"}} />
                    <span>Assign</span>
                </div>}
                {selectedAssigns.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        { name: `Remove ${selectedAssigns.length} assigns`, onClick: (e) => removeAssignments(e, selectedAssigns) },
                        { name: `Add quota to ${selectedAssigns.length} assigns`, onClick: (e) => addQuotaToAssignments(e, selectedAssigns) },
                        { name: `Reset quota usage for ${selectedAssigns.length} assigns`, onClick: (e) => resetQuotaUsage(e, selectedAssigns) },
                        { name: `Remove quotas ${selectedAssigns.length} assigns`, onClick: (e) => removeQuota(e, selectedAssigns) },
                        (selectedAssigns.length === 1 ? { name: "View quota history", onClick: () => viewQuotaHistory(selectedAssigns[0]) } : null),
                        (selectedAssigns.length === 1 ? { name: "View proxy history", onClick: () => viewProxyHistory(selectedAssigns[0]) } : null)
                    ].filter(t => t)}
                />}
            </div>
        </div>

        <FilterPanel
            theme="dark"
            accent="rgb(62, 87, 166)"
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "ProxyType", friendlyName: "Proxy type", type: "custom", varType: "string", data: [
                    {text: "Residental", value: "residental"},
                    {text: "Mobile", value: "mobile"},
                    {text: "Other", value: "other"}
                ]},
                {name: "UserID", friendlyName: "User", type: "custom", varType: "string", data: (users?.status === "ok" ? users.data : []).map(usr => {
                    return {text: usr.Username, value: usr.ID}
                })},
                {name: "Country", friendlyName: "Country", type: "custom", varType: "string", data: countries.map(c => {
                    return {text: c.name, value: c.code.toUpperCase()}
                })},
                {name: "Description", friendlyName: "Description", type: "string"}
            ]}
            filterCB={f => {
                setFilters(f);
            }}
        />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme="dark"
            checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedAssigns : false}
            checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
            headers={["No.", "ID", "User", "Type", "Country", "Date", "Last used", "Quota"]}
            customColumns={["auto", "auto", "auto", "auto", "auto", "auto", "auto"]}
            style={{columnGap: "20px", width: "100%"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data!"}]];

                let out = [];
                let i = 0;
                for (let item of data.data) {
                    i += 1;
                    let selectedCountry = countries.find(c => c.code === item.Country);
                    selectedCountry = selectedCountry ? selectedCountry.name : "Any";
                    out.push([
                        {keyID: item.ID, type: "text", text: i},
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item._User?.Username},
                        {keyID: item.ID, type: "text", text: item.ProxyType ?? "Any"},
                        {keyID: item.ID, type: "text", text: selectedCountry},
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString()},
                        {keyID: item.ID, type: "text", text: moment(item.lastUsed).fromNow()},
                        {keyID: item.ID, type: "text", text: getQutaData(item._Quota)},

                        (item.Description ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <>
                                <span style={{color: "gray"}}>Description: </span>
                                <span>{item.Description}</span>
                            </>}
                        ]} : null)
                    ].filter(f => f));
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show."}]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const Assign = props => {
    const [users, setUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [hasQuota, setHasQuota] = React.useState(false);
    const [selectedQuota, setSelectedQuota] = React.useState("B");
    const [proxyType, setProxyType] = React.useState();
    const [infoP, setInfoP] = React.useState({
        inputs: [],
        hadError: false,
        text: ""
    });

    const quotaRef = React.useRef();
    const descriptionRef = React.useRef();

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    const assignProxy = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            UserID: selectedUser,
            Country: selectedCountry,
            ProxyType: proxyType,
            Description: descriptionRef.current ? descriptionRef.current.value : ""
        };

        if (hasQuota) {
            let curQuota = quotaRef.current.value;
            if (isNaN(curQuota)) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["quota"], text: "Quota must be a number"}});
            if (curQuota < 0) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["quota"], text: "Quota must be greater than 0"}});

            curQuota = window.BigInt(curQuota);
            switch (selectedQuota) {
                case "B": break;
                case "KB": curQuota *= 1024n; break;
                case "MB": curQuota *= 1024n * 1024n; break;
                case "GB": curQuota *= 1024n * 1024n * 1024n; break;
                case "TB": curQuota *= 1024n * 1024n * 1024n * 1024n; break;
                case "PB": curQuota *= 1024n * 1024n * 1024n * 1024n * 1024n; break;
                default: break;
            };
            data["IncludeQuota"] = curQuota.toString();
        };

        if (!data.UserID) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username"], text: "User can't be empty"}});
        if (data.Country === undefined) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["country"], text: "Country can't be empty"}});
        if (data.ProxyType === undefined) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["proxy"], text: "Proxy type can't be empty"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/assign`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                return props.onClose();
            } else {
                return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Error while assigning a proxy, check your info and try again."}});
            };
        }).catch(() => {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__proxyAssignments__assign genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Assign</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {users ? <>
                {users.status === "ok" ? <>
                    <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("username") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Select an user</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="User"
                            data={users.data.map(usr => {
                                return {name: usr.Username, value: usr.ID};
                            })}
                            onChange={e => setSelectedUser(e?.value)}
                        />
                    </div>
                    {selectedUser && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("country") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Select a country</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="Country"
                            data={[{name: "Any country", value: null}, ...(()=>{
                                let out = [];
                                let foundUser = users.data.find(u => u.ID === selectedUser);
                                if (!foundUser) return [];
                                if (!Array.isArray(foundUser.Countries)) return [];
                                if (foundUser.Countries.length === 0) return [];

                                for (let country of foundUser.Countries) {
                                    out.push({name: country.name, value: country.code});
                                };

                                return out;
                            })()]}
                            onChange={e => setSelectedCountry(e?.value)}
                        />
                    </div>}

                    {selectedUser && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("proxy") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Proxy Type</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="Proxy Type"
                            data={[
                                {name: "Any proxy", value: null},
                                {name: "Generic (unsorted)", value: "generic"},
                                {name: "Residental", value: "residental"},
                                {name: "Mobile", value: "mobile"}
                            ]}
                            onChange={e => setProxyType(e?.value)}
                        />
                    </div>}
                    {selectedUser && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("proxy") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Description</p>
                        <input type="text" placeholder="Description" ref={descriptionRef} />
                    </div>}

                    <div className={`genericModal__wrap__input`}>
                        <p style={{display: "flex", alignItems: "center", gap: "10px"}}>
                            <Checkbox checked={hasQuota} onChange={e => e !== hasQuota && setHasQuota(e)} />
                            Include quota (limit)
                        </p>
                        {hasQuota && <div className="route__proxyAssignments__assign__splitInput">
                            <StyledInput ref={quotaRef} alternateStyle={false} type="number" placeholder="Quota" style={{
                                border: infoP.inputs.includes("quota") ? "1px solid #FF754C" : null
                            }} />
                            <Dropdown 
                                theme="dark"
                                accent="#6C5DD3"
                                data={[
                                    {name: "B", value: "B"},
                                    {name: "KB", value: "KB"},
                                    {name: "MB", value: "MB"},
                                    {name: "GB", value: "GB"},
                                    {name: "TB", value: "TB"},
                                    {name: "PB", value: "PB"},
                                ]}
                                selected={(()=>{
                                    if (!selectedQuota) return 0;
                                    if (selectedQuota === "B") return 0;
                                    if (selectedQuota === "KB") return 1;
                                    if (selectedQuota === "MB") return 2;
                                    if (selectedQuota === "GB") return 3;
                                    if (selectedQuota === "TB") return 4;
                                    if (selectedQuota === "PB") return 5;
                                })()}
                                onChange={e => setSelectedQuota(e?.value)}
                            />
                        </div>}
                        
                    </div>

                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                        <div className="genericModal__wrap__buttons__btn" onClick={e => assignProxy()}>
                            {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Save"}
                        </div>
                    </div>

                    {infoP.text && <div className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</div>}
                </> : <p className="genericModal__wrap__infoP" style={{opacity: 1}}>Error while fetching users!</p>}
            </> : <Spinner color="white" />}
        </div>
    </div>
};

const AddQuota = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        inputs: [],
        hadError: false,
        text: ""
    });
    const [selectedQuota, setSelectedQuota] = React.useState("B");

    const quotaRef = React.useRef();


    const addQuota = async () => {
        let curQuota = quotaRef.current.value;
        if (isNaN(curQuota) || !quotaRef.current.value) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["quota"], text: "Quota must be a number"}});

        curQuota = window.BigInt(curQuota);
        switch (selectedQuota) {
            case "B": break;
            case "KB": curQuota *= 1024n; break;
            case "MB": curQuota *= 1024n * 1024n; break;
            case "GB": curQuota *= 1024n * 1024n * 1024n; break;
            case "TB": curQuota *= 1024n * 1024n * 1024n * 1024n; break;
            case "PB": curQuota *= 1024n * 1024n * 1024n * 1024n * 1024n; break;
            default: break;
        };
        
        
        setSpinner(true);
        for (let item of props.items) {
            if (item._Quota) {
                // Add to existing quota
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/quotas/addQuotaAmount`,
                    data: {
                        ID: item._Quota.ID,
                        Amount: curQuota.toString()
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            } else {
                if (curQuota < 0) continue; //cant create new quota with negative starting limit
                // create new Quota
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/quotas/addQuota`,
                    data: {
                        UserID: item.UserID,
                        ProxyID: item.ID,
                        Quota: curQuota.toString()
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            };
        };
        setSpinner(false);
        if (props.onChange) props.onChange();
        props.onClose();
    };

    return <div className="route__proxyAssignments__assign genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add quota</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Quota to add / create</p>
                <div className="route__proxyAssignments__assign__splitInput">
                    <StyledInput ref={quotaRef} alternateStyle={false} type="number" placeholder="Quota" style={{
                        border: infoP.inputs.includes("quota") ? "1px solid #FF754C" : null
                    }} />
                    <Dropdown 
                        theme="dark"
                        accent="#6C5DD3"
                        data={[
                            {name: "B", value: "B"},
                            {name: "KB", value: "KB"},
                            {name: "MB", value: "MB"},
                            {name: "GB", value: "GB"},
                            {name: "TB", value: "TB"},
                            {name: "PB", value: "PB"},
                        ]}
                        selected={(()=>{
                            if (!selectedQuota) return 0;
                            if (selectedQuota === "B") return 0;
                            if (selectedQuota === "KB") return 1;
                            if (selectedQuota === "MB") return 2;
                            if (selectedQuota === "GB") return 3;
                            if (selectedQuota === "TB") return 4;
                            if (selectedQuota === "PB") return 5;
                        })()}
                        onChange={e => setSelectedQuota(e?.value)}
                    />
                </div>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={e => addQuota()}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Add"}
                </div>
            </div>

            {infoP.text && <div className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</div>}

        </div>
    </div>
};

export default ProxyAssignments;